import {Auth} from 'aws-amplify';

import awsconfig from 'aws-exports';

const amplifyConfig = {
  ...awsconfig,
  API: {
    endpoints: [
      {
        name: 'awsAPI',
        endpoint: 'https://6o2yihvsgf.execute-api.us-east-1.amazonaws.com/beta',
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export default amplifyConfig;
