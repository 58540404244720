import configuration from './amplify.config';
import Amplify, {API} from 'aws-amplify';

export const getDeviceDataApi = async (id: string) => {
  Amplify.configure(configuration);

  const apiName = 'awsAPI';
  const path = `/devicedata/${id}`;

  const response = await API.get(apiName, path, {});
  return response;
};
