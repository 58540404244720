import React from 'react';

import Button from 'components/Button/Button';

import Logo from 'assets/icons/Logo.svg';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {signOut} from 'store/slices/mainSlice';

interface ModalContentProps {
  onClose: () => void;
}

const ModalContent = ({onClose}: ModalContentProps) => {
  const dispatch = useAppDispatch();
  const {isLoading} = useAppSelector(({mainSlice: {signing}}) => ({
    isLoading: signing.isLoading,
  }));

  return (
    <div className="modal-content">
      <div className="modal-content__header">
        <img
          className="modal-content__logo"
          width={129}
          src={Logo}
          alt="h2zero"
        />

        <div className="modal-content__title">Log out from H2 Zero</div>

        <div className="modal-content__subtitle">
          Are you sure you would like to log out from your account?
        </div>
      </div>

      <div className="modal-content__inner">
        <Button
          className="modal-content__button"
          text="Cancel"
          onClick={onClose}
        />
        <Button
          className="modal-content__button"
          text="Log Out"
          outlined
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => dispatch(signOut())}
        />
      </div>
    </div>
  );
};

export default ModalContent;
