import styled from 'styled-components';
import {opacify} from 'polished';

const StyledDrawer = styled.div`
  width: 470px;

  .tank-loading {
    font-size: 20px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.mainText};
    text-align: center;
  }

  .drawer__close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;
    cursor: pointer;
  }

  .drawer-title {
    margin: 20px 0 0 20px;
    font-size: 22px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainText};
  }

  .notifications-list {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .results-list {
    overflow-y: scroll;
    margin-top: 25px;
    padding: 0 20px;
  }

  .results-empty {
    color: ${({theme}) => theme.colors.secondary};
    text-align: center;
    font-style: italic;
  }

  .results-item {
    position: relative;
    padding: 10px;
    background: ${({theme}) => theme.colors.widgetBackground};

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .results-item__date {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: ${({theme}) => theme.colors.secondary};
  }

  .results-item__descr {
    color: ${({theme}) => theme.colors.secondaryText};
  }

  .results-item__descr-key {
    font-weight: 600;
  }

  .results-item__descr_source {
    font-style: italic;
  }

  .sectionlist-container {
    display: block;
    margin-top: 60px;
    padding: 0;
  }

  .section-item-container {
    border: 0;
  }

  .section-item {
    padding: 0;
    margin: 20px 0;
  }

  .section-header {
    padding: 0;
  }

  .notification__item {
    display: flex;
    margin-left: 25px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .notification__logo {
    margin-right: 20px;
  }

  .notification__title {
    font-weight: 600;
  }

  .notification__date {
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: ${({theme}) => opacify(0.1, theme.colors.secondary)};
  }

  .notification__text {
    font-size: 16px;
    color: ${({theme}) => theme.colors.mainText};
  }

  .notification__time {
    font-size: 16px;
    color: ${({theme}) => opacify(0.1, theme.colors.secondary)};
  }

  @media (max-width: 600px) {
    width: 100vw;

    .notification__date {
      margin-top: 10px;
    }
  }
`;

export default StyledDrawer;
