import React from 'react';

import Cross from 'assets/icons/Cross.svg';
import StyledDrawer from './Drawer.style';
import {Results} from 'utils/models';

interface ResultsProps {
  results: Array<Results>;
  onDrawerClose: () => void;
}

const BacteriologicalResults = ({results, onDrawerClose}: ResultsProps) => {
  return (
    <StyledDrawer>
      <img
        className="drawer__close"
        src={Cross}
        alt="Close"
        onClick={onDrawerClose}
      />
      <div className="drawer-title">Bacteriological Results</div>

      <div className="results-list">
        {results.length === 0 ? (
          <div className="results-empty">Empty</div>
        ) : (
          results.map((result) => (
            <div className="results-item">
              <div className="results-item__date">{result.date}</div>
              <div className="results-item__descr">
                <span className="results-item__descr-key">E.coli/100ml: </span>
                {result.eColi}
              </div>
              <div className="results-item__descr">
                <span className="results-item__descr-key">
                  Total coliforms/100ml:{' '}
                </span>
                {result.totalColi}
              </div>
              <div className="results-item__descr">
                <span className="results-item__descr-key">Safe to drink: </span>
                {result.safe ? '✅ ' : '❌'}
              </div>
              <div className="results-item__descr">
                <span className="results-item__descr-key">Source: </span>
                <span className="results-item__descr_source">
                  {result.source}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
    </StyledDrawer>
  );
};

export default BacteriologicalResults;
