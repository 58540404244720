import styled from 'styled-components';

const PageContainer = styled.div<{
  isMobileLayout?: boolean;
  isSideBarOpen?: boolean;
}>`
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: ${({isMobileLayout}) => (isMobileLayout ? '0px' : '114px')};
  transition: all 0.2s ease;

  .page-title {
    margin-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.primary};
    transition: all 0.2s ease;
  }

  .page-subtitle {
    margin-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainText};
    transition: all 0.2s ease;
  }
`;

export default PageContainer;
