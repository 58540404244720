import {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {
  addTanks,
  getDeviceData,
  getNotifications,
  getWeather,
} from 'store/slices/tankSlice';

interface AnimationFiles {
  Sun: {
    [key: number]: NodeRequire;
  };
  Rain: {
    [key: number]: NodeRequire;
  };
}

export const animations: AnimationFiles = {
  Sun: {
    5: require('assets/animations/sun/5.json'),
    10: require('assets/animations/sun/10.json'),
    20: require('assets/animations/sun/20.json'),
    30: require('assets/animations/sun/30.json'),
    40: require('assets/animations/sun/40.json'),
    50: require('assets/animations/sun/50.json'),
    60: require('assets/animations/sun/60.json'),
    70: require('assets/animations/sun/70.json'),
    80: require('assets/animations/sun/80.json'),
    90: require('assets/animations/sun/90.json'),
    100: require('assets/animations/sun/100.json'),
  },
  Rain: {
    5: require('assets/animations/rain/5.json'),
    10: require('assets/animations/rain/10.json'),
    20: require('assets/animations/rain/20.json'),
    30: require('assets/animations/rain/30.json'),
    40: require('assets/animations/rain/40.json'),
    50: require('assets/animations/rain/50.json'),
    60: require('assets/animations/rain/60.json'),
    70: require('assets/animations/rain/70.json'),
    80: require('assets/animations/rain/80.json'),
    90: require('assets/animations/rain/90.json'),
    100: require('assets/animations/rain/100.json'),
  },
};

const useHomeHook = () => {
  const [positionFixed, setPositionFixed] = useState(false);
  const [tooltipProps, settooltipProps] = useState({
    visible: false,
    top: 0,
    left: 0,
    datum: {
      date: '',
      gallons: '',
      difference: '',
      colorOfDiff: '#0627447D',
    },
  });
  const dispatch = useAppDispatch();
  const {weather, deviceIDs, tanks, notifications} = useAppSelector(
    ({userSlice: {user}, tankSlice}) => ({
      weather: tankSlice.weather,
      deviceIDs: user.data.deviceIDs,
      tanks: tankSlice.tanks,
      notifications: tankSlice.notifications,
    }),
  );

  useEffect(() => {
    if (deviceIDs.length > 0) {
      dispatch(addTanks(deviceIDs));
    }
  }, [deviceIDs, dispatch]);

  useEffect(() => {
    deviceIDs.forEach((tankID: string) => {
      if (
        tanks[tankID] &&
        !tanks[tankID].loaded &&
        !tanks[tankID].isLoading &&
        !tanks[tankID].error
      ) {
        dispatch(getDeviceData(tankID));
      }
    });
  }, [dispatch, deviceIDs, tanks]);

  useEffect(() => {
    deviceIDs.forEach((tankID: string) => {
      if (
        notifications[tankID] &&
        !notifications[tankID].loaded &&
        !notifications[tankID].isLoading &&
        !notifications[tankID].error
      ) {
        dispatch(getNotifications(tankID));
      }
    });
  }, [dispatch, deviceIDs, notifications]);

  useEffect(() => {
    deviceIDs.forEach((tankID: string) => {
      if (
        tanks[tankID] &&
        tanks[tankID].data.id &&
        weather.data[tankID] &&
        !weather.data[tankID].loaded &&
        !weather.data[tankID].isLoading &&
        !weather.data[tankID].error
      ) {
        dispatch(
          getWeather({
            id: tanks[tankID].data.id,
            lat: tanks[tankID].data.lat,
            lng: tanks[tankID].data.lng,
          }),
        );
      }
    });
  }, [dispatch, deviceIDs, weather, tanks]);

  useEffect(() => {
    const checkOffset = () => {
      if (window.pageYOffset > 103) {
        setPositionFixed(true);
      } else {
        setPositionFixed(false);
      }
    };

    window.addEventListener('scroll', checkOffset);

    return () => {
      window.removeEventListener('scroll', checkOffset);
    };
  }, []);

  const handleTooltip = (
    visible: boolean,
    top: number = 0,
    left: number = 0,
    datum: typeof tooltipProps.datum = tooltipProps.datum,
  ) => {
    settooltipProps({
      visible: visible,
      top: top,
      left: left,
      datum: datum,
    });
  };

  return {
    positionFixed,
    tooltipProps,
    handleTooltip,
  };
};

export default useHomeHook;
