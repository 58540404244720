import styled from 'styled-components';

import PageContainer from 'style/styled-components/PageContainer';

const StyledProfile = styled(PageContainer)<{
  isMobileLayout?: boolean;
  isSideBarOpen?: boolean;
}>`
  .profile__inner {
    margin-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    font-size: 22px;
    color: ${({theme}) => theme.colors.mainText};
    transition: all 0.2s ease;
  }

  .profile__inner {
    display: flex;
    flex-direction: column;
  }

  .profile__item {
    display: flex;
    margin-bottom: 10px;
  }

  .profile__item_key {
    font-weight: 600;
  }

  .profile__item_value {
    margin-left: 10px;
  }

  .profile__logout {
    max-width: 200px;
    position: absolute;
    right: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    bottom: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    z-index: 1000;
  }
`;

export default StyledProfile;
