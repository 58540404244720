import styled from 'styled-components';

const StyledSign = styled.div`
  min-height: 100%;
  display: flex;

  .sign__left-part {
    flex: 1;
    background-color: ${({theme}) => theme.colors.primary};
  }

  .sign__right-part {
    height: 100%;
    flex: 2;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sign__back-arrow {
    align-self: flex-start;
    margin: 5vh 0 5vh 25px;
  }

  .sign__inner {
    margin: 0 auto;
    width: 90%;
    max-width: 446px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sign__inner_start {
    height: 100%;
  }

  .sign__inner_sign-in {
    justify-content: flex-start;
  }

  .sign__logo {
    margin-bottom: 170px;
  }

  .sign__logo_title {
    margin-bottom: 15px;
  }

  .sign__button:not(:last-child) {
    margin-bottom: 30px;
  }

  .sign__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header__title {
    margin-bottom: 8px;
    font-size: 32px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainText};
  }

  .header__subtitle {
    font-size: 16px;
    color: ${({theme}) => theme.colors.secondaryText};
  }

  .sign__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }

  .sign__form_sign-up {
    margin-top: 35px;
  }

  .sign__input-item {
    width: 100%;
    margin-bottom: 25px;
  }

  .sign__input-item_double {
    display: flex;
    justify-content: space-between;
  }

  .sign__input {
    width: 100%;
  }

  .sign__input_half {
    width: 45%;
  }

  .sign__input_phone {
    margin-bottom: 0;
  }

  .sign__input-item_phone {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 25px;
  }

  .phone-code {
    height: 95%;
    display: flex;
    margin-right: 5px;
    padding: 0 5px 2px;
    line-height: 1.6;
    align-items: flex-end;
    border-bottom: ${({theme}) => '1px solid' + theme.colors.mainText};
  }

  .password-tip {
    margin-top: 10px;
    font-size: 11px;
    color: #949494;
  }

  .sign__submit-button {
    margin-top: 30px;
  }

  .sign__footer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .sign__footer_sign-in {
    justify-content: flex-end;
  }

  .sign__terms {
    margin-top: 20px;
    margin-bottom: 55px;
    font-size: 14px;
    color: ${({theme}) => theme.colors.mainText};
    text-align: center;
  }

  .sign__link {
    font-weight: 500;
    color: ${({theme}) => theme.colors.primary};
    cursor: pointer;
  }

  .sign__another-page {
    padding-top: 20px;
    padding-bottom: 25px;
    font-size: 14px;
  }
`;

export default StyledSign;
