import {colors, fontFamily} from 'utils/constants';

export const themes = {
  light: {
    colors: {
      ...colors.light,
    },
    fontFamily,
  },
};
