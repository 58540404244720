import moment from 'moment';

import {UserData, History, Tank} from 'utils/models';

interface TankResponse {
  deviceData: {[key: string]: any};
  additDeviceData: {[key: string]: any};
  averageDailyUse: {[key: string]: any};
  results: Array<{[key: string]: any}>;
}

interface UserResponse {
  [key: string]: string;
}

export const formatTooltipData = (datum: {[key: string]: any}) => ({
  date: `${moment(new Date(datum.date)).format('MMMM')} ${datum.day}, ${
    datum.year
  }`,
  gallons: datum.gallons,
  difference: datum.difference,
  colorOfDiff:
    Math.sign(datum.difference) === 0
      ? '#0627447D'
      : Math.sign(datum.difference) < 0
      ? 'red'
      : 'green',
});

export const formatResponse = {
  user: (response: UserResponse): UserData => {
    const deviceIDsArray = Object.entries(response)
      .filter((item) => item[0].includes('device_id'))
      .sort()
      .map((item) => item[1]);

    return {
      user_id: response['custom:user_id'],
      userName: response['custom:name'] || null,
      email: response.email,
      phone_number: response.phone_number,
      parish: response['custom:parish'],
      deviceIDs: deviceIDsArray,
    };
  },

  tank: (response: TankResponse): Tank => {
    const viewValue =
      Math.round(parseInt(response.deviceData.percent)) <= 5
        ? 5
        : Math.round(parseInt(response.deviceData.percent) * 0.1) * 10;
    const results = response.results.map((item) => ({
      eColi: item['E.coli/100ml'].S,
      safe: item['Safe to drink'].S.toUpperCase() === 'YES' ? true : false,
      source: item.Source.S,
      totalColi: item['Total coliforms/100ml'].S,
      date: moment(new Date(+item.date.N * 1000)).format('DD MMMM YYYY'),
    }));
    if (results.length > 0) {
      console.log(results);
    }
    return {
      id: response.additDeviceData.Items[0].device_id.S,
      address: response.additDeviceData.Items[0].address.S,
      averageDailyUse: response.additDeviceData.Items[0].average_daily_use.N,
      capacity: response.additDeviceData.Items[0].cap.N,
      depth: response.additDeviceData.Items[0].depth.N,
      length: response.additDeviceData.Items[0]['length'].N,
      n_level: response.additDeviceData.Items[0].n_level.N,
      name: response.additDeviceData.Items[0].name.S,
      parish: response.additDeviceData.Items[0].parish.S,
      people: response.additDeviceData.Items[0].people.N,
      width: response.additDeviceData.Items[0].width.N,
      gallons: response.deviceData.Gallons
        ? response.deviceData.Gallons.toString()
        : null,
      lat: response.deviceData.lat ? response.deviceData.lat.toString() : null,
      lng: response.deviceData.lng ? response.deviceData.lng.toString() : null,
      percent: response.deviceData.percent,
      status: response.deviceData.status,
      updated: response.deviceData.updated,
      wifi: response.deviceData.wifi_signal,
      title: response.deviceData.title,
      daysToEmpty: response.averageDailyUse.noDaysLeft.N,
      viewValue: viewValue,
      viewWeather: 'Sun',
      results: results,
    };
  },

  weather: (response: {[key: string]: any}) => {
    const weekWeather = [];

    for (let i = 0; i < 7; i++) {
      const momentDay = moment(new Date(response.daily?.[i].dt));
      const formattedDay = momentDay.format(
        `${
          momentDay.date() === new Date().getDate()
            ? '[Today]'
            : momentDay.date() === moment(new Date()).add(1, 'days').date()
            ? '[Tomorrow]'
            : 'dddd'
        }, MMMM DD`,
      );

      const formattedDayWeather = {
        date: formattedDay,
        chanceOfRain: Math.round(
          Number.parseFloat(response.daily?.[i].chanceofRain) * 100,
        ),
        windSpeed: Math.round(parseFloat(response.daily?.[i].wind)),
      };
      weekWeather.push(formattedDayWeather);
    }

    return weekWeather;
  },

  history: (response: {[key: string]: {[key: string]: Array<any>}}) => {
    const historyArray: Array<History> = [];

    response.history.items.forEach((item) => {
      historyArray.push({
        gallons: +item.g_l?.N,
        date: item.rep?.S,
        day: moment(new Date(item.rep?.S)).format('DD'),
        weekDay: moment(new Date(item.rep?.S)).format('ddd'),
        month: moment(new Date(item.rep?.S)).format('MMM'),
        year: moment(new Date(item.rep?.S)).format('YYYY'),
        difference: +item.nextDayGdiff?.N,
      });
    });

    return historyArray;
  },

  notifications: (response: Array<any>) => {
    const formattedNot = response.map((item) => ({
      title: moment(new Date(item.completed_at * 1000)).format('DD MMMM YYYY'),
      data: [item.contents],
      time: moment(new Date(item.completed_at * 1000)).format('LT'),
    }));

    const datesOfNots = Array.from(
      new Set(formattedNot.map((item) => item.title)),
    );
    const notificationsArray = datesOfNots.map((date) => ({
      title: date,
      data: formattedNot
        .filter((item) => item.title === date)
        .map((item) => ({text: item.data[0], time: item.time}))
        .flat(),
    }));

    return notificationsArray;
  },

  services: (response: Array<any>) => {
    const servicesArray: Array<any> = [];
    const sponsored: Array<any> = [];
    let extraServicesObj = {};

    response.forEach((item) => {
      if (item.sponsored) {
        sponsored.push(item.sponsored);
      } else if (Object.keys(item)[0] !== 'extraServices') {
        servicesArray.push(item);
      }
    });

    const extraServices = response.find(
      (item) => Object.keys(item)[0] === 'extraServices',
    );

    if (extraServices) {
      const extraServicesMap = new Map();

      extraServices.extraServices.forEach((item: any) => {
        extraServicesMap.set(item.service, item.info);
        extraServicesObj = Object.fromEntries(extraServicesMap);
      });
    }

    return {
      sponsored: sponsored,
      services: servicesArray,
      extraServices: extraServicesObj,
    };
  },
};
