import React, {useEffect, useState} from 'react';
import {VisibilityContext} from 'react-horizontal-scrolling-menu';

const RightArrow = () => {
  const [accessable, setAccessable] = useState(false);
  const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);

  useEffect(() => {
    setTimeout(() => {
      setAccessable(true);
    }, 500);
  }, []);

  if (!accessable) return null;

  return (
    <div
      className={`tank__scroll-button tank__scroll-button_right ${
        isLastItemVisible ? 'tank__scroll-button_disabled' : ''
      }`}
      onClick={() => scrollNext()}
    >
      {'>'}
    </div>
  );
};

export default RightArrow;
