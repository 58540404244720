import configuration from './amplify.config';
import Amplify, {API} from 'aws-amplify';

export const getWeatherApi = async (lat: string, lon: string) => {
  Amplify.configure(configuration);

  const apiName = 'awsAPI';
  const path = '/weather';
  const init = {
    queryStringParameters: {
      lat: lat,
      lon: lon,
    },
  };

  const response = await API.get(apiName, path, init);
  return response;
};
