import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import Button from 'components/Button/Button';

import Logo from 'assets/icons/Logo.svg';
import {FormikProps, FormikProvider} from 'formik';
import {SignUpValues} from '../Sign.hook';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useAppSelector} from 'store/hooks';

type SignUpProps = {
  setSubPage: Function;
  formik: FormikProps<SignUpValues>;
  parishes: Array<string>;
};

const SignUp = ({setSubPage, formik, parishes}: SignUpProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const {isLoading} = useAppSelector(({mainSlice: {signing}}) => ({
    isLoading: signing.isLoading,
  }));

  return (
    <div className="sign__inner">
      <div className="sign__header">
        <img
          className="sign__logo sign__logo_title"
          src={Logo}
          alt="logo"
          width={138}
        />

        <div className="header__title">Create new account</div>
        <div className="header__subtitle">
          Please fill in the form to continue
        </div>
      </div>

      <FormikProvider value={formik}>
        <form className="sign__form sign__form_sign-up">
          <div className="sign__input-item">
            <TextField
              className="sign__input"
              label="Full Name"
              variant="standard"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.name && formik.touched.name)}
            />
          </div>

          <div className="sign__input-item sign__input-item_phone">
            <div className="phone-code">+1</div>
            <InputMask
              mask="(999)999-99-99"
              disabled={false}
              maskChar=""
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.phone && formik.touched.phone)}
            >
              {/*
          // @ts-ignore */}
              {(props) => (
                <TextField
                  className="sign__input sign__input_phone"
                  label="(000) 000 - 00 - 00"
                  hiddenLabel
                  variant="standard"
                  sx={{
                    '& label.Mui-focused': {
                      display: 'none',
                    },
                    '& label.MuiFormLabel-filled': {
                      display: 'none',
                    },
                  }}
                  {...props}
                />
              )}
            </InputMask>
          </div>

          <div className="sign__input-item">
            <TextField
              className="sign__input"
              label="Email address"
              variant="standard"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.username && formik.touched.username)}
            />
          </div>

          <div className="sign__input-item">
            <TextField
              className="sign__input"
              label="Password"
              variant="standard"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.password && formik.touched.password)}
            />

            <div className="password-tip">Must be at least 8 characters</div>
          </div>

          <div className="sign__input-item sign__input-item_double">
            <FormControl className="sign__input sign__input_half">
              <InputLabel id="parish-label">Parish</InputLabel>
              <Select
                label="Parish"
                labelId="parish-label"
                name="parish"
                value={formik.values.parish}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.parish && formik.touched.parish)}
              >
                {parishes.map((parish) => (
                  <MenuItem key={parish} value={parish}>
                    {parish}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className="sign__input sign__input_half"
              label="Device ID"
              variant="standard"
              name="device_id_a"
              value={formik.values.device_id_a}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.errors.device_id_a && formik.touched.device_id_a,
              )}
            />
          </div>

          <Button
            className="sign__submit-button"
            text="Sign Up"
            onClick={formik.handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </form>
      </FormikProvider>

      <div className="sign__footer">
        <div className="sign__terms">
          <div>By creating the account you agree with</div>
          <div>
            <span className="sign__link">Terms&Conditions</span> and{' '}
            <span className="sign__link">Privacy Policy</span>
          </div>
        </div>

        <div className="sign__another-page">
          Already have an account?{' '}
          <span className="sign__link" onClick={() => setSubPage('SignIn')}>
            Sign In
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
