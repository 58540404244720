import {useState} from 'react';
import {object, string} from 'yup';
import {useFormik} from 'formik';

import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import SignStart from './components/SignStart';

import {useAppDispatch} from 'store/hooks';
import {signIn, signUp} from 'store/slices/mainSlice';

type SubPageState = 'SignStart' | 'SignIn' | 'SignUp';

export type SignUpValues = {
  username: string;
  password: string;
  name: string;
  phone: string;
  parish: string;
  device_id_a: string;
};

export type SignInValues = {
  email: string;
  password: string;
};

const useSignHook = () => {
  const [subPage, setSubPage] = useState<SubPageState>('SignStart');
  const dispatch = useAppDispatch();

  const formatPhoneNumber = (string: string) => {
    return parseInt(string.replace(/[^\d]/g, '')).toString();
  };

  const parishes = [
    'Devonshire',
    'Hamilton',
    'Paget',
    'Pembroke',
    "Sandy's",
    "Smith's",
    'Southampton',
    "St George's",
    'Warwick',
  ];

  const signUpForm = useFormik({
    initialValues: {
      username: '',
      password: '',
      name: '',
      phone: '',
      parish: '',
      device_id_a: '',
    },
    validationSchema: object().shape({
      username: string().email().required(),
      password: string().min(8).required(),
      name: string().required(),
      phone: string()
        .test(
          'len',
          'Enter a valid phone number',
          (val) => !!val && formatPhoneNumber(val).length === 10,
        )
        .required(),
      parish: string().required(),
      device_id_a: string().required(),
    }),
    onSubmit: async (values) => {
      dispatch(signUp({...values, phone: formatPhoneNumber(values.phone)}));
    },
  });

  const signInForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: object().shape({
      email: string().email().required(),
      password: string().required(),
    }),
    onSubmit: async (values) => {
      dispatch(signIn(values));
    },
  });

  const subPages: {[key: string]: JSX.Element} = {
    SignStart: <SignStart setSubPage={setSubPage} />,
    SignIn: <SignIn formik={signInForm} setSubPage={setSubPage} />,
    SignUp: (
      <SignUp formik={signUpForm} setSubPage={setSubPage} parishes={parishes} />
    ),
  };

  return {
    page: subPages[subPage],
    pageName: subPage,
    setSubPage,
  };
};

export default useSignHook;
