import React from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';

import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import History from './History';
import TankInfo from './Info';
import Card from './Card';

import {useAppSelector} from 'store/hooks';

const TankItem = ({
  deviceID,
  isMobileLayout,
  handleTooltip,
  tooltipProps,
}: {
  deviceID: string;
  isMobileLayout: boolean;
  handleTooltip: Function;
  tooltipProps: {[key: string]: any};
}) => {
  const {tank} = useAppSelector(({tankSlice: {tanks}}) => ({
    tank: tanks[deviceID],
  }));

  if (isMobileLayout) {
    return (
      <div className="tank-item">
        <Card itemId="card" tank={tank} />

        <History
          itemId="history"
          deviceID={deviceID}
          tank={tank}
          handleTooltip={handleTooltip}
          tooltipProps={tooltipProps}
        />

        <TankInfo itemId="info" tank={tank} />
      </div>
    );
  }

  return (
    <div className="tank-item">
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={() => null}
      >
        <Card itemId="card" tank={tank} />

        <History
          itemId="history"
          deviceID={deviceID}
          tank={tank}
          handleTooltip={handleTooltip}
          tooltipProps={tooltipProps}
        />

        <TankInfo itemId="info" tank={tank} />
      </ScrollMenu>
    </div>
  );
};

export default TankItem;
