export const colors = {
  light: {
    primary: '#0075D9',
    secondary: '#0627444D',
    mainText: '#062744',
    secondaryText: '#062744CC',
    background: '#FFFFFF',
    widgetBackground: '#F3F4F6',
  },
};

export const fontFamily = 'Poppins, sans-serif';
