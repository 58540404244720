import {opacify} from 'polished';
import styled from 'styled-components';

const StyledModal = styled.div`
  width: 346px;
  height: 354px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.colors.background};
  border-radius: 10px;

  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-content__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 17px;
    text-align: center;
  }

  .modal-content__logo {
    margin-bottom: 30px;
  }

  .modal-content__title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainText};
  }

  .modal-content__subtitle {
    font-size: 16px;
    color: ${({theme}) => opacify(0.1, theme.colors.secondary)};
  }

  .modal-content__button {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export default StyledModal;
