import {createTheme} from '@mui/material/styles';

const materialTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  palette: {
    secondary: {
      main: '#fff',
    },
  },
});

export default materialTheme;
