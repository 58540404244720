import configuration from './amplify.config';
import Amplify, {API} from 'aws-amplify';

export const getHistoryApi = async (id: string, days: string) => {
  Amplify.configure(configuration);

  const apiName = 'awsAPI';
  const path = `/history/${id}`;
  const init = {
    queryStringParameters: {
      from: `${days}day`,
      to: '0day',
      pageSize: days,
      ascending: 'true',
    },
  };

  const response = await API.get(apiName, path, init);
  return response;
};
