import styled from 'styled-components';
import {mix} from 'polished';

const StyledButton = styled.button<{outlined?: boolean}>`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: ${({outlined, theme}) =>
    outlined ? theme.colors.primary : theme.colors.background};
  border: ${({theme}) => `1px solid ${theme.colors.primary}`};
  border-radius: 30px;
  background-color: ${({outlined, theme}) =>
    outlined ? '#FFFFFF' : theme.colors.primary};
  appearance: none;
  transition: all 0.1s linear;
  cursor: pointer;

  &:active {
    background: ${({outlined, theme}) =>
      outlined
        ? mix(0.2, theme.colors.primary, theme.colors.background)
        : mix(0.2, theme.colors.background, theme.colors.primary)};
  }

  .button__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default StyledButton;
