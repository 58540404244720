import {useEffect, useState} from 'react';

import {useAppDispatch} from 'store/hooks';
import {getServices} from 'store/slices/userSlice';

const useServiceHook = () => {
  const [expanded, setExpanded] = useState<null | number>(null);
  const [reqServiceOpen, setReqServiceOpen] = useState(false);
  const [selectedService, setDelectedService] = useState<null | string>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const handleExpand = (index: number) => {
    if (index === expanded) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setReqServiceOpen(open);
  };

  return {
    expanded,
    handleExpand,
    reqServiceOpen,
    setReqServiceOpen,
    toggleDrawer,
    selectedService,
    setDelectedService,
  };
};

export default useServiceHook;
