import React, {useEffect, useRef, useState} from 'react';
import Lottie, {LottieRef} from 'lottie-react';
import {Rings} from 'react-loader-spinner';
import {useTheme} from 'styled-components';

import {animations} from '../Home.hook';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {selectTank} from 'store/slices/tankSlice';
import {SingleTankState} from 'utils/models';

export interface TankCardProps {
  tank: SingleTankState;
  itemId: string;
}

const Card = ({tank, itemId}: TankCardProps) => {
  const [isRainAnimation, setisRainAnimation] = useState(false);
  const dispatch = useAppDispatch();
  const {selected} = useAppSelector(({tankSlice: {weather}}) => ({
    selected: weather.selected,
  }));

  const lottieRef: LottieRef = useRef(null);
  const theme: any = useTheme();

  const startAnimation = () => {
    setisRainAnimation(false);
    let timer;
    clearTimeout(timer);

    if (tank.data.viewWeather === 'Rain') {
      setisRainAnimation(true);
      if (lottieRef.current) {
        lottieRef.current.play();
      }
      timer = setTimeout(() => {
        setisRainAnimation(false);
      }, 12000);
    } else {
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (lottieRef.current) {
      startAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tank.data.viewWeather, lottieRef]);

  return (
    <div
      className={`tank-card__wrapper ${
        tank.data.id === selected ? 'tank-card__wrapper_selected' : ''
      }`}
      onClick={() => dispatch(selectTank(tank.data.id))}
    >
      <div className="tank-card tank-card__selected">
        {tank.isLoading ? (
          <div className="tank-loading">
            <Rings
              height="100"
              width="100"
              color={theme.colors.primary}
              ariaLabel="loading"
            />
          </div>
        ) : tank.error ? (
          <div className="tank-loading">Tank data loading error</div>
        ) : (
          <div className="tank__card-inner">
            <Lottie
              className="tank-card__animation"
              animationData={
                animations[tank.data.viewWeather][tank.data.viewValue]
              }
              lottieRef={lottieRef}
              loop={isRainAnimation}
            />

            <div className="tank-card__info">
              <div className="tank-card__main-info">
                <div className="tank-card__info-item tank-card__info-item_title">
                  {tank.data.title}
                </div>
                <div className="tank-card__info-item">
                  Status: {tank.data.status}
                </div>
                <div className="tank-card__info-item">
                  Wi-Fi Signal: {tank.data.wifi}
                </div>
                <div className="tank-card__info-item">
                  Updated: {tank.data.updated}
                </div>
              </div>

              <div className="tank-card__average-info">
                <div className="tank-card__second-info-item">
                  <div className="tank-card__second-info-title">
                    {tank.data.averageDailyUse}
                  </div>
                  <div className="tank-card__second-info-text">
                    Average daily use
                  </div>
                </div>

                <div className="tank-card__second-info-item">
                  <div className="tank-card__second-info-title">
                    {tank.data.daysToEmpty}
                  </div>
                  <div className="tank-card__second-info-text">
                    Days to empty
                  </div>
                </div>
              </div>

              <div className="tank-card__bottom-info">
                <div className="tank-card__bottom-info-item">
                  <div className="tank-card__second-info-title tank-card__second-info-title_bottom">
                    {tank.data.gallons}
                  </div>
                  <div className="tank-card__second-info-text tank-card__second-info-text_bottom">
                    gallons
                  </div>
                </div>
                <div className="tank-card__bottom-info-item">
                  <div className="tank-card__second-info-title tank-card__second-info-title_bottom">
                    {tank.data.percent}
                  </div>
                  <div className="tank-card__second-info-text tank-card__second-info-text_bottom">
                    filled
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
