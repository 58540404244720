import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {getAdditDeviceDataApi} from 'api/getAdditDeviceDataApi';
import {getAverageDailyUseApi} from 'api/getAverageDailyUseApi';
import {getBactResultsApi} from 'api/getBactResultsApi';
import {getDeviceDataApi} from 'api/getDeviceDataApi';
import {getHistoryApi} from 'api/getHistoryApi';
import {getNotificationsApi} from 'api/getNotificationsApi';
import {getWeatherApi} from 'api/getWeatherApi';
import {formatResponse} from 'utils/format';
import {TankState} from 'utils/models';

const initialState: TankState = {
  initialized: false,
  weather: {
    selected: null,
    data: {},
  },
  tanks: {},
  history: {},
  notifications: {},
};

export const getDeviceData = createAsyncThunk(
  'tankSlice/getDeviceData',
  async (id: string) => {
    const deviceData = await getDeviceDataApi(id);
    const additDeviceData = await getAdditDeviceDataApi(id);
    const averageDailyUse = await getAverageDailyUseApi(id);
    const results = await getBactResultsApi(id);

    const formattedResponse = formatResponse.tank({
      deviceData,
      additDeviceData,
      averageDailyUse,
      results,
    });

    return formattedResponse;
  },
);

export const getWeather = createAsyncThunk(
  'tankSlice/getWeather',
  async (props: {id: string; lat: string; lng: string}) => {
    const weather = await getWeatherApi(props.lat, props.lng);

    const formattedResponse = formatResponse.weather(weather);

    return formattedResponse;
  },
);

export const getHistory = createAsyncThunk(
  'tankSlice/getHistory',
  async ({id, days}: {id: string; days: string}) => {
    const history = await getHistoryApi(id, days);

    const formattedResponse = formatResponse.history({
      history,
    });

    return formattedResponse;
  },
);

export const getNotifications = createAsyncThunk(
  'tankSlice/getNotifications',
  async (id: string) => {
    const notifications = await getNotificationsApi(id);

    const formattedResponse = formatResponse.notifications(notifications);

    return formattedResponse;
  },
);

const tankSlice = createSlice({
  name: 'tankSlice',
  initialState,
  reducers: {
    addTanks: (state, {payload}) => {
      payload.forEach((tankID: string) => {
        state.tanks[tankID] = {
          isLoading: false,
          loaded: false,
          error: false,
          data: {
            viewValue: 5,
            viewWeather: 'Sun',
            results: [],
          },
        };
      });

      payload.forEach((tankID: string) => {
        state.history[tankID] = {
          isLoading: false,
          loaded: false,
          error: false,
          isWeek: true,
          data: [],
        };
      });

      state.weather.selected = payload[0];
      payload.forEach((tankID: string) => {
        state.weather.data[tankID] = {
          isLoading: false,
          loaded: false,
          error: false,
          data: [],
        };
      });

      payload.forEach((tankID: string) => {
        state.notifications[tankID] = {
          isLoading: false,
          loaded: false,
          error: false,
          data: [],
        };
      });

      state.initialized = true;
    },

    toggleHistoryView: (state, {payload}) => {
      state.history[payload.deviceID].isWeek = payload.value;
    },

    selectTank: (state, {payload}) => {
      state.weather.selected = payload;
    },

    resetTankState: (state) => {
      state.initialized = false;
      state.weather = {
        selected: null,
        data: {},
      };
      state.tanks = {};
      state.history = {};
      state.notifications = {};
    },
  },

  extraReducers: (builder) => {
    // Device Data
    builder.addCase(getDeviceData.pending, (state, {meta: {arg}}) => {
      state.tanks[arg].isLoading = true;
      state.tanks[arg].loaded = false;
      state.tanks[arg].error = false;
    });
    builder.addCase(
      getDeviceData.fulfilled,
      (state, {payload, meta: {arg}}) => {
        state.tanks[arg].isLoading = false;
        state.tanks[arg].loaded = true;
        state.tanks[arg].error = false;
        state.tanks[arg].data = payload;
      },
    );
    builder.addCase(getDeviceData.rejected, (state, {meta: {arg}}) => {
      state.tanks[arg].isLoading = false;
      state.tanks[arg].loaded = true;
      state.tanks[arg].error = true;
    });

    // Weather
    builder.addCase(getWeather.pending, (state, {meta: {arg}}) => {
      state.weather.data[arg.id].isLoading = true;
      state.weather.data[arg.id].loaded = false;
      state.weather.data[arg.id].error = false;
    });
    builder.addCase(getWeather.fulfilled, (state, {payload, meta: {arg}}) => {
      state.weather.data[arg.id].isLoading = false;
      state.weather.data[arg.id].loaded = true;
      state.weather.data[arg.id].error = false;
      state.weather.data[arg.id].data = payload;
      state.tanks[arg.id].data.viewWeather =
        payload[0].chanceOfRain > 40 ? 'Rain' : 'Sun';
    });
    builder.addCase(getWeather.rejected, (state, {meta: {arg}}) => {
      state.weather.data[arg.id].isLoading = false;
      state.weather.data[arg.id].loaded = true;
      state.weather.data[arg.id].error = true;
    });

    // History
    builder.addCase(getHistory.pending, (state, {meta: {arg}}) => {
      state.history[arg.id].isLoading = true;
      state.history[arg.id].loaded = false;
      state.history[arg.id].error = false;
    });
    builder.addCase(getHistory.fulfilled, (state, {payload, meta: {arg}}) => {
      state.history[arg.id].isLoading = false;
      state.history[arg.id].loaded = true;
      state.history[arg.id].error = false;
      state.history[arg.id].data = payload;
    });
    builder.addCase(getHistory.rejected, (state, {meta: {arg}}) => {
      state.history[arg.id].isLoading = false;
      state.history[arg.id].loaded = true;
      state.history[arg.id].error = true;
    });

    // Notifications
    builder.addCase(getNotifications.pending, (state, {meta: {arg}}) => {
      state.notifications[arg].isLoading = true;
      state.notifications[arg].loaded = false;
      state.notifications[arg].error = false;
    });
    builder.addCase(
      getNotifications.fulfilled,
      (state, {payload, meta: {arg}}) => {
        state.notifications[arg].isLoading = false;
        state.notifications[arg].loaded = true;
        state.notifications[arg].error = false;
        state.notifications[arg].data = payload;
      },
    );
    builder.addCase(getNotifications.rejected, (state, {meta: {arg}}) => {
      state.notifications[arg].isLoading = false;
      state.notifications[arg].loaded = true;
      state.notifications[arg].error = true;
    });
  },
});
export const {addTanks, selectTank, toggleHistoryView, resetTankState} =
  tankSlice.actions;
export default tankSlice.reducer;
