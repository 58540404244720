import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Auth} from 'aws-amplify';

import {SignInValues, SignUpValues} from 'pages/Sign/Sign.hook';
import {MainState} from 'utils/models';

const initialState: MainState = {
  authChecked: false,
  signed: false,
  signing: {
    isLoading: false,
    error: false,
  },
  theme: 'light',
};

export const checkAuth = createAsyncThunk('mainSlice/checkAuth', async () => {
  await Auth.currentAuthenticatedUser();
});

export const signIn = createAsyncThunk(
  'mainSlice/signIn',
  async (props: SignInValues) => {
    await Auth.signIn(props.email, props.password);
  },
);

export const signUp = createAsyncThunk(
  'mainSlice/signUp',
  async (props: SignUpValues) => {
    await Auth.signUp({
      username: props.username,
      password: props.password,
      attributes: {
        email: props.username,
        'custom:name': props.name,
        phone_number: `+1${props.phone}`,
        'custom:device_id_a': props.device_id_a,
        'custom:parish': props.parish,
      },
    });

    await Auth.signIn(props.username, props.password);
  },
);

export const signOut = createAsyncThunk('mainSlice/signOut', async () => {
  await Auth.signOut();
});

const mainSlice = createSlice({
  name: 'mainSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //  Check Auth
    builder.addCase(checkAuth.fulfilled, (state) => {
      state.authChecked = true;
      state.signed = true;
    });
    builder.addCase(checkAuth.rejected, (state) => {
      state.authChecked = true;
      state.signed = false;
    });

    // Sign Up
    builder.addCase(signUp.pending, (state) => {
      state.signing.isLoading = true;
      state.signing.error = false;
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.signing.isLoading = false;
      state.signed = true;
      state.signing.error = false;
    });
    builder.addCase(signUp.rejected, (state) => {
      state.signing.isLoading = false;
      state.signed = false;
      state.signing.error = true;
    });

    // Sign In
    builder.addCase(signIn.pending, (state) => {
      state.signing.isLoading = true;
      state.signing.error = false;
    });
    builder.addCase(signIn.fulfilled, (state) => {
      state.signing.isLoading = false;
      state.signed = true;
      state.signing.error = false;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.signing.isLoading = false;
      state.signed = false;
      state.signing.error = true;
    });

    // Sign Out
    builder.addCase(signOut.pending, (state) => {
      state.signing.isLoading = true;
      state.signing.error = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.signing.isLoading = false;
      state.signed = false;
      state.signing.error = false;
    });
    builder.addCase(signOut.rejected, (state) => {
      state.signing.isLoading = false;
      state.signing.error = true;
    });
  },
});

export default mainSlice.reducer;
