import styled from 'styled-components';
import {opacify} from 'polished';

const StyledRequestService = styled.div`
  width: 470px;
  height: 100%;
  position: relative;
  /* margin: 50px 30px 0; */

  .request-service__close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1000;
    cursor: pointer;
  }

  .request-service__title {
    margin-top: 50px;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 28px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainText};
  }

  .extra-services-inner {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 30px;
  }

  .extra-service__item {
    display: flex;
    padding: 26px;
    background: ${({theme}) => theme.colors.widgetBackground};
    border: 1px solid ${({theme}) => theme.colors.widgetBackground};
    border-radius: 10px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .extra-service__item_selected {
    background: ${({theme}) => opacify(-0.86, theme.colors.primary)};
    border-color: ${({theme}) => theme.colors.primary};
  }

  .extra-service__radio {
    margin-right: 10px;
  }

  .extra-services-footer {
    padding-top: 15px;
    margin-top: auto;
    padding-bottom: 50px;
  }

  .extra-service__selected-description {
    min-height: 21px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.primary};
    text-align: center;
  }

  .extra-service__message {
    min-height: 21px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .extra-service__message_success {
    color: green;
  }

  .extra-service__message_error {
    color: red;
  }

  @media (max-width: 600px) {
    width: 100vw;
  }
`;

export default StyledRequestService;
