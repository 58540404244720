import React, {useEffect, useState} from 'react';
import {VisibilityContext} from 'react-horizontal-scrolling-menu';

const LeftArrow = () => {
  const [accessable, setAccessable] = useState(false);
  const {isFirstItemVisible, scrollPrev} = React.useContext(VisibilityContext);

  useEffect(() => {
    setTimeout(() => {
      setAccessable(true);
    }, 500);
  }, []);

  if (!accessable) return null;

  return (
    <div
      className={`tank__scroll-button tank__scroll-button_left ${
        isFirstItemVisible ? 'tank__scroll-button_disabled' : ''
      }`}
      onClick={() => scrollPrev()}
    >
      {'<'}
    </div>
  );
};

export default LeftArrow;
