import React from 'react';

import Button from 'components/Button/Button';
import Logo from 'assets/icons/Logo.svg';

type SignStartProps = {
  setSubPage: Function;
};

const SignStart = ({setSubPage}: SignStartProps) => {
  return (
    <div className="sign__inner sign__inner_start">
      <img className="sign__logo" src={Logo} alt="logo" width={184} />

      <Button
        className="sign__button"
        onClick={() => setSubPage('SignUp')}
        text="Sign Up"
      />
      <Button
        className="sign__button"
        onClick={() => setSubPage('SignIn')}
        outlined
        text="Sign In"
      />
    </div>
  );
};

export default SignStart;
