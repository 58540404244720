import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    color: inherit;
    outline: none;
    list-style: none;
  }

  html,
  body,
  #root {
    min-height: 100%;
  }

  html,
  body {
    display: flex;
  }


  body,
  #root {
    width: 100%;
  }

  html {
    font-family: Poppins;
    color: ${({theme}: any) => theme.colors.mainText};
    background-color: ${({theme}: any) => theme.colors.background};
  }

  #root {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
