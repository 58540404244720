import React from 'react';
// @ts-ignore
import ReactSectionList from 'react-sectionlist';
import {Rings} from 'react-loader-spinner';
import {useTheme} from 'styled-components';

import Cross from 'assets/icons/Cross.svg';
import LogoCircle from 'assets/icons/LogoCircle.svg';
import {Notification, SingleNotificationsState} from 'utils/models';
import StyledDrawer from './Drawer.style';

interface NotificationsProps {
  notifications: SingleNotificationsState;
  onDrawerClose: () => void;
}

interface SingleNotification {
  text: string;
  time: string;
}

const Notifications = ({notifications, onDrawerClose}: NotificationsProps) => {
  const theme: any = useTheme();

  return (
    <StyledDrawer>
      <img
        className="drawer__close"
        src={Cross}
        alt="Close"
        onClick={onDrawerClose}
      />
      <div className="drawer-title">Notifications</div>

      <div className="notifications-list">
        {notifications.isLoading ? (
          <div className="tank-loading">
            <Rings
              height="100"
              width="100"
              color={theme.colors.primary}
              ariaLabel="loading"
            />
          </div>
        ) : notifications.error ? (
          <div className="tank-loading">Notifications loading error</div>
        ) : (
          <ReactSectionList
            keyExtractor={(item: Notification, index: number) =>
              item.title + index
            }
            renderSectionHeader={(item: Notification) => (
              <div className="notification__date">{item.title}</div>
            )}
            renderItem={(item: SingleNotification) => (
              <div className="notification__item">
                <div className="notification__logo">
                  <img src={LogoCircle} alt="h2zero" />
                </div>

                <div className="notification__inner">
                  <div className="notification__title">H2 Zero</div>
                  <div className="notification__text">{item.text}</div>
                  <div className="notification__time">{item.time}</div>
                </div>
              </div>
            )}
            sections={notifications.data}
          />
        )}
      </div>
    </StyledDrawer>
  );
};

export default Notifications;
