import configuration from './amplify.config';
import Amplify, {API} from 'aws-amplify';

export const getWaterTruckServicesApi = async () => {
  Amplify.configure(configuration);

  const apiName = 'awsAPI';
  const path = '/waterservice';

  const response = await API.get(apiName, path, {});
  return response;
};
