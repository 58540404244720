import {useTheme} from 'styled-components';
import React, {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {VictoryChart, VictoryBar, VictoryAxis, VictoryContainer} from 'victory';
import moment from 'moment';
import {Rings} from 'react-loader-spinner';
// @ts-ignore
import OutsideClickHandler from 'react-outside-click-handler';

import {getHistory, toggleHistoryView} from 'store/slices/tankSlice';
import {SingleTankState} from 'utils/models';
import {formatTooltipData} from 'utils/format';

export interface TankHistoryProps {
  deviceID: string;
  tank: SingleTankState;
  itemId: string;
  handleTooltip: Function;
  tooltipProps: {[key: string]: any};
}

const styles = {
  xAxis: {
    axis: {stroke: '#FFFFFF'},
    grid: {stroke: '#0627442D', strokeDasharray: '4, 5'},
    tickLabels: {
      fontSize: 11,
      padding: 5,
      fill: '#0627445D',
      fontFamily: 'Poppins',
    },
  },
  yAxis: {
    axis: {stroke: '#FFFFFF'},
    grid: {stroke: '#0627441D'},
    tickLabels: {
      fontSize: 11,
      padding: 20,
      fill: '#0627445D',
      fontFamily: 'Poppins',
    },
  },
  maxAxis: {
    axis: {stroke: 'red'},
    axisLabel: {
      padding: 0,
      fontSize: 11,
      fontFamily: 'Poppins',
      fill: 'red',
    },
  },
};

const History = ({itemId, deviceID, tank, handleTooltip}: TankHistoryProps) => {
  const chartContainerRef = useRef(null);
  const theme: any = useTheme();
  const dispatch = useAppDispatch();

  const {history} = useAppSelector(({tankSlice}) => ({
    history: tankSlice.history[deviceID],
  }));

  useEffect(() => {
    dispatch(
      getHistory({
        id: deviceID,
        days: history.isWeek ? '7' : (moment().daysInMonth() - 1).toString(),
      }),
    );
  }, [deviceID, dispatch, history.isWeek]);

  return (
    <div className="tank-history">
      <div className="tank-item__title">History</div>

      <div className="tank-history__switch">
        <div
          className={`tank-history__switch-item ${
            history.isWeek ? 'tank-history__switch-item_active' : ''
          }`}
          onClick={() =>
            dispatch(toggleHistoryView({deviceID: deviceID, value: true}))
          }
        >
          Week
        </div>
        <div
          className={`tank-history__switch-item ${
            history.isWeek ? '' : 'tank-history__switch-item_active'
          }`}
          onClick={() =>
            dispatch(toggleHistoryView({deviceID: deviceID, value: false}))
          }
        >
          Month
        </div>
      </div>
      <OutsideClickHandler onOutsideClick={() => handleTooltip(false)}>
        <div className="tank-history__chart-container" ref={chartContainerRef}>
          {history.isLoading ? (
            <div className="tank-loading">
              <Rings
                height="100"
                width="100"
                color={theme.colors.primary}
                ariaLabel="loading"
              />
            </div>
          ) : history.error ? (
            <div className="tank-loading">Tank history loading error</div>
          ) : (
            <>
              <VictoryChart
                containerComponent={
                  <VictoryContainer className="tank-history__chart-inner-container" />
                }
                width={470}
                height={390}
                domainPadding={{x: 15}}
                maxDomain={
                  history.isWeek
                    ? {
                        x: history.isWeek ? 7 : 31,
                      }
                    : {
                        x: history.isWeek ? 7 : 31,
                        y: +(tank.data.capacity || 0),
                      }
                }
                padding={{left: 0, right: 60, top: 10, bottom: 20}}
              >
                <VictoryAxis
                  style={styles.xAxis}
                  tickCount={history.isWeek ? 7 : 4}
                />
                {!history.isWeek && (
                  <VictoryAxis style={styles.maxAxis} orientation="top" />
                )}
                <VictoryAxis
                  style={styles.yAxis}
                  orientation="right"
                  dependentAxis
                />
                <VictoryBar
                  data={history.data}
                  y="gallons"
                  x={history.isWeek ? 'weekDay' : 'day'}
                  style={{data: {fill: theme.colors.primary}}}
                  alignment="middle"
                  barWidth={history.isWeek ? 20 : 5}
                  cornerRadius={{top: 3}}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onClick: (e) => {
                          return [
                            {
                              target: 'data',
                              mutation: (props) => {
                                handleTooltip(
                                  true,
                                  // @ts-ignore
                                  e.pageY,
                                  // @ts-ignore
                                  e.pageX,
                                  formatTooltipData(props.datum),
                                );
                                console.log(props);
                              },
                            },
                          ];
                        },
                      },
                    },
                  ]}
                />
              </VictoryChart>
            </>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default History;
