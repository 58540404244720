import React from 'react';
import {Link, useLocation} from 'react-router-dom';
// @ts-ignore
import OutsideClickHandler from 'react-outside-click-handler';
import MenuIcon from '@mui/icons-material/Menu';

import Logo from 'assets/icons/Logo.svg';
import RoutesData from 'routes/Routes.data';
import StyledSideBar from './SideBar.style';
import {RouteProps} from 'utils/models';

interface SideBarProps extends RouteProps {
  isSideBarOpen: boolean;
  setIsSideBarOpen: Function;
}

const SideBar = ({
  isMobileLayout,
  isSideBarOpen,
  setIsSideBarOpen,
}: SideBarProps) => {
  const {pathname} = useLocation();

  return (
    <OutsideClickHandler onOutsideClick={() => setIsSideBarOpen(false)}>
      <StyledSideBar
        isMobileLayout={isMobileLayout}
        isSideBarOpen={isSideBarOpen}
      >
        {isMobileLayout && (
          <div className="sidebar__burger">
            <MenuIcon
              className="sidebar__burger-icon"
              color="primary"
              width={25}
              onClick={() => setIsSideBarOpen(!isSideBarOpen)}
            />
          </div>
        )}
        <div className="sidebar">
          {!isMobileLayout && (
            <img className="sidebar__logo" src={Logo} alt="logo" width={80} />
          )}

          <nav className="navbar">
            <ul>
              {RoutesData.loggedIn.map((navItem) => (
                <li
                  className={`nav-item nav-item${
                    pathname === navItem.path ? '_active' : ''
                  }`}
                  key={navItem.name}
                >
                  <Link className="nav-item__link" to={navItem.path}>
                    <img
                      src={require(`../../assets/icons/SideBar/${
                        navItem.name +
                        (pathname === navItem.path ? '-active' : '')
                      }.svg`)}
                      alt={navItem.name}
                      height={isMobileLayout ? 25 : 32}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </StyledSideBar>
    </OutsideClickHandler>
  );
};

export default SideBar;
