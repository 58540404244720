import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Auth} from 'aws-amplify';

import {getWaterTruckServicesApi} from 'api/getWaterTruckServicesApi';
import {formatResponse} from 'utils/format';
import {UserState} from 'utils/models';
import {requestServiceApi} from 'api/requestServiceApi';

const initialState: UserState = {
  user: {
    loaded: false,
    error: false,
    data: {
      userName: null,
      phone_number: null,
      email: null,
      parish: null,
      user_id: null,
      deviceIDs: [],
    },
  },
  services: {
    isLoading: false,
    loaded: false,
    error: false,
    request: {
      isLoading: false,
      loaded: false,
      error: false,
    },
    data: {
      sponsored: [],
      services: [],
      extraServices: {},
    },
  },
};

export const getUserInfo = createAsyncThunk(
  'userSlice/getUserInfo',
  async () => {
    const {attributes} = await Auth.currentAuthenticatedUser();

    const formattedResponse = formatResponse.user(attributes);

    return formattedResponse;
  },
);

export const getServices = createAsyncThunk(
  'userSlice/getServices',
  async () => {
    const services = await getWaterTruckServicesApi();
    const formattedResponse = formatResponse.services(services);

    return formattedResponse;
  },
);

export const requestService = createAsyncThunk(
  'userSlice/requestService',
  async ({id, service}: {id: string; service: string}) => {
    const response = await requestServiceApi(id, service);

    return response;
  },
);

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    resetRequestState: (state) => {
      state.services.request = {
        isLoading: false,
        loaded: false,
        error: false,
      };
    },

    resetUserState: (state) => {
      state.user = {
        loaded: false,
        error: false,
        data: {
          userName: null,
          phone_number: null,
          email: null,
          parish: null,
          user_id: null,
          deviceIDs: [],
        },
      };
      state.services = {
        isLoading: false,
        loaded: false,
        error: false,
        request: {
          isLoading: false,
          loaded: false,
          error: false,
        },
        data: {
          sponsored: [],
          services: [],
          extraServices: {},
        },
      };
    },
  },

  extraReducers: (builder) => {
    // User
    builder.addCase(getUserInfo.pending, (state) => {
      state.user.loaded = false;
      state.user.error = false;
    });
    builder.addCase(getUserInfo.fulfilled, (state, {payload}) => {
      state.user.loaded = true;
      state.user.error = false;

      state.user.data = payload;
    });
    builder.addCase(getUserInfo.rejected, (state) => {
      state.user.loaded = true;
      state.user.error = true;
    });

    // Services
    builder.addCase(getServices.pending, (state) => {
      state.services.isLoading = true;
      state.services.loaded = false;
      state.services.error = false;
    });
    builder.addCase(getServices.fulfilled, (state, {payload}) => {
      state.services.isLoading = false;
      state.services.loaded = true;
      state.services.error = false;

      state.services.data = payload;
    });
    builder.addCase(getServices.rejected, (state) => {
      state.services.isLoading = false;
      state.services.loaded = true;
      state.services.error = true;
    });

    // Request Service
    builder.addCase(requestService.pending, (state) => {
      state.services.request.isLoading = true;
      state.services.request.loaded = false;
      state.services.request.error = false;
    });
    builder.addCase(requestService.fulfilled, (state) => {
      state.services.request.isLoading = false;
      state.services.request.loaded = true;
      state.services.request.error = false;
    });
    builder.addCase(requestService.rejected, (state) => {
      state.services.request.isLoading = false;
      state.services.request.loaded = true;
      state.services.request.error = true;
    });
  },
});

export const {resetRequestState, resetUserState} = userSlice.actions;

export default userSlice.reducer;
