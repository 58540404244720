import React from 'react';
import {Rings} from 'react-loader-spinner';
import {useTheme} from 'styled-components';

import ServicesList from './components/ServicesList';

import StyledService from './Service.style';
import {useAppSelector} from 'store/hooks';
import useServiceHook from './Services.hook';
import {RouteProps} from 'utils/models';

const Service = ({isMobileLayout, isSideBarOpen}: RouteProps) => {
  const {
    expanded,
    handleExpand,
    reqServiceOpen,
    toggleDrawer,
    selectedService,
    setDelectedService,
  } = useServiceHook();
  const {services, isLoading, error} = useAppSelector(
    ({userSlice: {services}}) => ({
      services: services.data,
      isLoading: services.isLoading,
      error: services.error,
    }),
  );
  const theme: any = useTheme();

  return (
    <StyledService
      isMobileLayout={isMobileLayout}
      isSideBarOpen={isSideBarOpen}
    >
      <div className="page-title">Water Service</div>
      {isLoading ? (
        <div className="services-loading">
          <Rings
            height="200"
            width="200"
            color={theme.colors.primary}
            ariaLabel="loading"
          />
        </div>
      ) : error ? (
        <div className="services-loading">Services loading error</div>
      ) : (
        <ServicesList
          services={services}
          expanded={expanded}
          handleExpand={handleExpand}
          reqServiceOpen={reqServiceOpen}
          toggleDrawer={toggleDrawer}
          selectedService={selectedService}
          setDelectedService={setDelectedService}
        />
      )}
    </StyledService>
  );
};

export default Service;
