import React, {useState} from 'react';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import ModalContent from './components/ModalContent';

import {useAppSelector} from 'store/hooks';
import StyledProfile from './Profile.style';
import {RouteProps} from 'utils/models';

const Profile = ({isMobileLayout, isSideBarOpen}: RouteProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {user} = useAppSelector(({userSlice: {user}}) => ({
    user: user,
  }));

  return (
    <StyledProfile
      isMobileLayout={isMobileLayout}
      isSideBarOpen={isSideBarOpen}
    >
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContent onClose={() => setModalOpen(false)} />
      </Modal>

      <div className="page-title">Profile</div>

      <div className="profile__inner">
        {user.data.username && (
          <div className="profile__item">
            <div className="profile__item_key">Username:</div>
            <div className="profile__item_value">{user.data.username}</div>
          </div>
        )}

        <div className="profile__item">
          <div className="profile__item_key">Email:</div>
          <div className="profile__item_value">{user.data.email}</div>
        </div>

        <div className="profile__item">
          <div className="profile__item_key">Phone:</div>
          <div className="profile__item_value">{user.data.phone_number}</div>
        </div>

        <div className="profile__item">
          <div className="profile__item_key">Parish:</div>
          <div className="profile__item_value">{user.data.parish}</div>
        </div>
      </div>

      <Button
        className="profile__logout"
        text="Log Out"
        onClick={() => setModalOpen(true)}
      />
    </StyledProfile>
  );
};

export default Profile;
