import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {CookiesProvider} from 'react-cookie';

import App from './App';

import StyledThemeProvider from 'style/StyledThemeProvider';
import {persistor, store} from './store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledThemeProvider>
          <App />
        </StyledThemeProvider>
      </PersistGate>
    </Provider>
  </CookiesProvider>,
);
