import styled from 'styled-components';
import {opacify} from 'polished';

import PageContainer from 'style/styled-components/PageContainer';

const StyledServices = styled(PageContainer)<{
  isMobileLayout?: boolean;
  isSideBarOpen?: boolean;
}>`
  .services-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.mainText};
    text-align: center;
  }

  .services-list {
    max-width: 637px;
    display: flex;
    flex-direction: column;
  }

  .service-sponsored-item {
    margin-bottom: 30px;
    margin-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    transition: all 0.2s ease;
  }

  .service-sponsored-name {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainText};
  }

  .service-sponsored__inner {
    height: auto;
    margin-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '0px')};
  }

  .service-item {
    margin-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    padding: ${({isMobileLayout}) => (isMobileLayout ? '0 10px' : '0')};
    transition: all 0.2s ease;
  }

  .service-header {
    display: flex;
    justify-content: space-between;
    margin-left: -10px;
    padding: 10px 13px 10px 8px;
    border-radius: 10px;
    cursor: pointer;
  }

  .service-arrow {
    transition: all 0.2s ease;
  }

  .service-header_expanded {
    background-color: ${({theme}) => opacify(-0.8, theme.colors.primary)};
  }

  .service-name {
    font-size: 18px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.mainText};
  }

  .service__inner {
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
  }

  .service__inner_expanded {
    height: 73px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .service__description {
    font-size: 14px;
    font-weight: 500;
    color: ${({theme}) => opacify(0.1, theme.colors.secondary)};

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .service__description_paris {
    text-transform: capitalize;
  }

  .service__phone {
    color: ${({theme}) => theme.colors.primary};
  }

  .services-button {
    max-width: 446px;
    margin: 40px auto 0;
  }

  @media (max-width: 768px) {
    .services-list {
      margin: 0 auto;
    }

    .services-button {
      max-width: 90vw;
    }
  }
`;

export default StyledServices;
