import configuration from './amplify.config';
import Amplify, {API} from 'aws-amplify';

export const requestServiceApi = async (tankId: string, service: string) => {
  Amplify.configure(configuration);

  const apiName = 'awsAPI';
  const path = `/servicerequest`;
  const init = {
    queryStringParameters: {
      id: tankId,
      service: service,
    },
  };

  const response = await API.post(apiName, path, init);
  return response;
};
