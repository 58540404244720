import {useEffect, useState} from 'react';
import Amplify from 'aws-amplify';
import OneSignal from 'react-onesignal';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {checkAuth} from 'store/slices/mainSlice';
import awsconfig from 'aws-exports';
import {getUserInfo, resetUserState} from 'store/slices/userSlice';
import {resetTankState} from 'store/slices/tankSlice';

const useAppHook = () => {
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const {signed} = useAppSelector(({mainSlice}) => ({
    signed: mainSlice.signed,
  }));

  Amplify.configure({
    ...awsconfig,
    Analytics: {
      disabled: true,
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if (signed) {
      dispatch(getUserInfo());
    } else {
      dispatch(resetTankState());
      dispatch(resetUserState());
    }
  }, [dispatch, signed]);

  useEffect(() => {
    const changeLayout = () => {
      if (window.innerWidth < 769) {
        setIsMobileLayout(true);
      } else {
        setIsMobileLayout(false);
      }
    };
    changeLayout();

    window.addEventListener('resize', changeLayout);

    return () => {
      window.removeEventListener('resize', changeLayout);
    };
  }, []);

  useEffect(() => {
    (async () => {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID as string,
        allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
      });
    })();
  }, []);

  return {
    isMobileLayout,
    isSideBarOpen,
    setIsSideBarOpen,
  };
};

export default useAppHook;
