import {opacify} from 'polished';
import styled from 'styled-components';

const StyledSideBar = styled.div<{
  isMobileLayout: boolean;
  isSideBarOpen: boolean;
}>`
  .sidebar__burger {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1003;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 15px;
    background-color: ${({theme}) => theme.colors.background};
  }

  .sidebar__burger-icon {
    transform: ${({isSideBarOpen}) =>
      isSideBarOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 0.2s ease;
  }

  .sidebar {
    height: 100%;
    width: 113px;
    margin-left: ${({isMobileLayout, isSideBarOpen}) =>
      isMobileLayout && !isSideBarOpen
        ? '-113px'
        : isMobileLayout && isSideBarOpen
        ? '-55px'
        : '0'};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({theme}) => theme.colors.background};
    border-right: ${({theme, isMobileLayout, isSideBarOpen}) =>
      isMobileLayout && !isSideBarOpen
        ? 0
        : '1px solid ' + opacify(-0.15, theme.colors.secondary)};
    overflow: ${({isMobileLayout, isSideBarOpen}) =>
      isMobileLayout && !isSideBarOpen ? 'hidden' : 'visible'};
    transition: all 0.2s ease;
  }

  .sidebar__logo {
    margin: 45px 0 80px;
  }

  .navbar {
    width: 100%;
    margin-top: ${({isMobileLayout}) => (isMobileLayout ? '50px' : '0')};
  }

  .nav-item {
    width: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }

  .nav-item_active {
    border-right: 3px solid ${({theme}) => theme.colors.primary};
  }

  .nav-item__link {
    width: 100%;
    display: flex;
    justify-content: ${({isMobileLayout}) =>
      isMobileLayout ? 'flex-end' : 'center'};
    align-items: center;
    padding-right: ${({isMobileLayout}) => (isMobileLayout ? '15px' : '0')};
  }
`;

export default StyledSideBar;
