import React from 'react';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate,
} from 'react-router-dom';

import RoutesData from './Routes.data';
import {useAppSelector} from 'store/hooks';

import NavBar from 'components/SideBar/SideBar';
import {RouteProps} from 'utils/models';

interface RoutesProps extends RouteProps {
  setIsSideBarOpen: Function;
}

const Routes = ({
  isMobileLayout,
  isSideBarOpen,
  setIsSideBarOpen,
}: RoutesProps) => {
  const {signed} = useAppSelector(({mainSlice}) => ({
    signed: mainSlice.signed,
  }));

  return (
    <Router>
      {signed && (
        <NavBar
          isMobileLayout={isMobileLayout}
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
        />
      )}

      <Switch>
        {signed ? (
          <>
            {RoutesData.loggedIn.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={route.component(isMobileLayout, isSideBarOpen)}
              />
            ))}

            <Route path="*" element={<Navigate to="/home" replace />} />
          </>
        ) : (
          <>
            {RoutesData.loggedOut.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={route.component}
              />
            ))}

            <Route path="*" element={<Navigate to="/sign" replace />} />
          </>
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
