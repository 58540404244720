import configuration from './amplify.config';
import Amplify, {API} from 'aws-amplify';

export const getAverageDailyUseApi = async (id: string) => {
  Amplify.configure(configuration);

  const apiName = 'awsAPI';
  const path = `/getaveragedailyuse`;
  const init = {
    queryStringParameters: {
      id: id,
    },
  };

  const response = await API.get(apiName, path, init);
  return response;
};
