import React from 'react';

import Weather from './components/Weather';
import TankItem from './components/TankItem';

import StyledHome from './Home.style';
import useHomeHook from './Home.hook';
import {useAppSelector} from 'store/hooks';
import {RouteProps} from 'utils/models';

const Home = ({isMobileLayout, isSideBarOpen}: RouteProps) => {
  const {positionFixed, tooltipProps, handleTooltip} = useHomeHook();

  const {deviceIDs, initialized} = useAppSelector(
    ({userSlice: {user}, tankSlice}) => ({
      deviceIDs: user.data.deviceIDs,
      initialized: tankSlice.initialized,
    }),
  );

  return (
    <StyledHome
      isMobileLayout={isMobileLayout}
      isSideBarOpen={isSideBarOpen}
      positionFixed={positionFixed}
      tooltipX={tooltipProps.left}
      tooltipY={tooltipProps.top}
      tooltipVisible={tooltipProps.visible}
    >
      <div className="page-title">Home</div>

      {positionFixed && <div style={{height: '155px'}}></div>}

      <div className="weather-wrapper">
        <div className="page-subtitle page-subtitle_weather">Week Forecast</div>
        <div className="weather">{initialized && <Weather />}</div>
      </div>

      <div className="tanks">
        <div className="page-subtitle">My Tanks</div>

        {initialized &&
          deviceIDs.map((deviceID: string) => (
            <TankItem
              key={deviceID}
              deviceID={deviceID}
              isMobileLayout={isMobileLayout}
              handleTooltip={handleTooltip}
              tooltipProps={tooltipProps}
            />
          ))}

        <div className="tooltip">
          <div>{tooltipProps.datum.date}</div>
          <div>
            <span className="tooltip-gallons">
              {tooltipProps.datum.gallons}
            </span>{' '}
            Gallons{' '}
            <span
              className="tooltip-difference"
              style={{color: tooltipProps.datum.colorOfDiff}}
            >
              ({tooltipProps.datum.difference})
            </span>
          </div>
          <div className="tooltip-arrow"></div>
        </div>
      </div>
    </StyledHome>
  );
};

export default Home;
