import React from 'react';
import Drawer from '@mui/material/Drawer';

import RequestService from './RequestService';
import Button from 'components/Button/Button';

import ServiceArrow from 'assets/icons/ServiceArrow.svg';
import {ServicesData} from 'utils/models';
import {Service as ServiceType} from 'utils/models';

interface ServicesListProps {
  services: ServicesData;
  expanded: null | number;
  handleExpand: Function;
  reqServiceOpen: boolean;
  toggleDrawer: Function;
  selectedService: null | string;
  setDelectedService: Function;
}

const ServicesList = ({
  services,
  expanded,
  handleExpand,
  reqServiceOpen,
  toggleDrawer,
  selectedService,
  setDelectedService,
}: ServicesListProps) => {
  return (
    <div className="services-list">
      {services.sponsored.map((service: ServiceType) => (
        <div className="service-sponsored-item" key={service.name}>
          <div className="service-sponsored-name">{service.name}</div>

          <div className="service-sponsored__inner">
            <div className="service__description">Parish: {service.parish}</div>
            <div className="service__description">
              Phone: <span className="service__phone">{service.phone}</span>
            </div>
            <div className="service__description">Stars: {service.stars}</div>
          </div>
        </div>
      ))}

      {services.services.map((service: ServiceType, index: number) => (
        <div className="service-item" key={service.name}>
          <div
            className={`service-header ${
              index === expanded ? 'service-header_expanded' : ''
            }`}
            onClick={() => handleExpand(index)}
          >
            <div className="service-name">{service.name}</div>
            <img
              className="service-arrow"
              style={{
                transform: `rotate(${index === expanded ? '180deg' : '0'})`,
              }}
              src={ServiceArrow}
              alt="^"
            />
          </div>

          <div
            className={`service__inner ${
              index === expanded ? 'service__inner_expanded' : ''
            }`}
          >
            <div className="service__description">
              Parish:{' '}
              <span className="service__description_paris">
                {service.parish}
              </span>
            </div>
            <div className="service__description">
              Phone: <span className="service__phone">{service.phone}</span>
            </div>
            <div className="service__description">Stars: {service.stars}</div>
          </div>
        </div>
      ))}

      <Button
        className="services-button"
        text="Request a Service"
        onClick={toggleDrawer(true)}
      />

      <Drawer
        anchor="right"
        open={reqServiceOpen}
        onClose={toggleDrawer(false)}
      >
        <RequestService
          selected={selectedService}
          handleSelect={setDelectedService}
          onDrawerClose={toggleDrawer(false)}
        />
      </Drawer>
    </div>
  );
};

export default ServicesList;
