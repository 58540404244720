import styled from 'styled-components';

import PageContainer from 'style/styled-components/PageContainer';
import {opacify} from 'polished';

const StyledHome = styled(PageContainer)<{
  isMobileLayout?: boolean;
  isSideBarOpen?: boolean;
  positionFixed?: boolean;
  tooltipVisible: boolean;
  tooltipX: number;
  tooltipY: number;
}>`
  .page-subtitle_weather {
    margin-bottom: ${({isMobileLayout}) => (isMobileLayout ? '15px' : '20px')};
    margin-top: ${({isMobileLayout}) => (isMobileLayout ? '5px' : '0px')};
    padding-left: ${({positionFixed, isMobileLayout}) =>
      positionFixed && isMobileLayout ? '50px' : '0px'};
    transition: all 0.2s ease;
  }

  .tanks {
    margin-top: 30px;
  }

  .tank-item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .react-horizontal-scrolling-menu--wrapper {
    position: relative;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    align-items: center;
    padding-right: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    padding-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
    overflow: hidden;
    transition: all 0.2s ease;
  }

  .weather-wrapper {
    position: ${({positionFixed}) => (positionFixed ? 'fixed' : 'unset')};
    top: 0;
    left: ${({isMobileLayout}) => (isMobileLayout ? '0px' : '114px')};
    right: 0;
    z-index: 1001;
    padding-top: ${({positionFixed}) => (positionFixed ? '3px' : '0px')};
    background: ${({theme}) => theme.colors.background};
    transition: all 0.2s ease;
  }

  .weather-container {
    padding-left: ${({isMobileLayout}) => (isMobileLayout ? '10px' : '50px')};
  }

  .tank__scroll-button {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    z-index: 1000;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({theme}) => theme.colors.background};
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 16px;
    user-select: none;
    cursor: pointer;
  }

  .tank__scroll-button_disabled {
    display: none;
  }

  .tank__scroll-button_right {
    right: 5px;
  }

  .tank__scroll-button_left {
    left: 5px;
  }

  .tank-loading {
    font-size: 20px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.mainText};
    text-align: center;
  }

  .tank-loading_weather {
    margin-left: ${({isMobileLayout, isSideBarOpen}) =>
      isMobileLayout && !isSideBarOpen ? '10px' : '50px'};
  }

  .weather {
    height: 95px;
  }

  .weather__item {
    width: 152px;
    padding: 10px;
  }

  .weather__item-title {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.primary};
  }

  .weather__item-inner {
    display: flex;
  }

  .weather__item-info {
    &:not(:last-child) {
      margin-right: 10px;
      padding-right: 10px;
      border-right: ${({theme}) =>
        '1px solid ' + opacify(-0.2, theme.colors.secondary)};
    }
  }

  .weather__item-info-title {
    color: ${({theme}) => theme.colors.secondary};
  }

  .weather__item-info-inner {
    display: flex;
  }

  .weather__item-info-value {
    margin-left: 3px;
    font-size: 12px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.mainText};
  }

  .tank-item__title {
    font-size: 22px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.mainText};
  }

  .tank-card {
    width: 389px;
    height: 100%;
    max-height: 539px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tank-card__wrapper {
    margin-right: 30px;
    padding: 2px;
    border: 2px solid transparent;
    border-radius: 10px;
    cursor: pointer;
  }

  .tank-card__wrapper_selected {
    border-color: ${({theme}) => theme.colors.primary};
  }

  .tank__card-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .tank-card__animation {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
  }

  .tank-card__info {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 40px;
    user-select: none;
  }

  .tank-card__main-info {
    width: 100%;
    font-size: 16px;
  }

  .tank-card__info-item {
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  .tank-card__info-item_title {
    font-weight: 600;
  }

  .tank-card__average-info {
    display: flex;
    margin-top: 15px;
  }

  .tank-card__second-info-item {
    text-align: center;

    &:not(:last-child) {
      padding-right: 30px;
      border-right: 1px solid #ffffffcc;
    }

    &:last-child {
      padding-left: 30px;
    }
  }

  .tank-card__second-info-title {
    font-size: 24px;
    font-weight: 600;
  }

  .tank-card__second-info-text {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.4;
  }

  .tank-card__second-info-title_bottom {
    margin-right: 5px;
  }

  .tank-card__second-info-text_bottom {
    margin-bottom: 5px;
  }

  .tank-card__bottom-info {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
  }

  .tank-card__bottom-info-item {
    display: flex;
    text-align: center;
    align-items: flex-end;

    &:not(:last-child) {
      margin-right: 60px;
    }
  }

  .tank-history {
    /* position: relative; */
    min-width: 470px;
    height: 479px;
    margin-right: 30px;
    padding: 30px;
    background: ${({theme}) => theme.colors.widgetBackground};
    border-radius: 10px;
    user-select: none;
  }

  .tooltip {
    position: absolute;
    top: ${({tooltipY}) => tooltipY || 0}px;
    left: ${({tooltipX}) => tooltipX || 0}px;
    z-index: 100;
    transform: translateY(-100%);
    display: ${({tooltipVisible}) => (tooltipVisible ? 'block' : 'none')};
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.secondary};
    background-color: ${({theme}) => theme.colors.background};
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 10px;
  }

  .tooltip-arrow {
    position: relative;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 5px;
      left: 0;
      z-index: 1011;
      border-right: 20px solid transparent;
      border-top: 13px solid ${({theme}) => theme.colors.secondary};
    }
  }

  .tooltip-gallons {
    font-size: 18px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.primary};
  }

  .tooltip-difference {
    font-size: 10px;
  }

  .tank-history__switch {
    display: flex;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .tank-history__switch-item {
    flex: 1;
    padding-bottom: 5px;
    color: ${({theme}) => opacify(0.1, theme.colors.secondary)};
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid
      ${({theme}) => opacify(-0.15, theme.colors.secondary)};
  }

  .tank-history__switch-item_active {
    color: ${({theme}) => theme.colors.primary};
    border-bottom: 3px solid ${({theme}) => theme.colors.primary};
  }

  .tank-history__chart-container {
    width: 471px;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tank-history__chart-inner-container {
    margin: 0 auto;
    border-left: 1px solid ${({theme}) => opacify(-0.2, theme.colors.secondary)};
    border-bottom: 1px solid
      ${({theme}) => opacify(-0.1, theme.colors.secondary)};

    path {
      cursor: pointer;
    }
  }

  .tank-info {
    min-width: 200px;
    height: 479px;
    display: flex;
    flex-direction: column;
    padding: 30px 25px;
    font-weight: 500;
    background: ${({theme}) => theme.colors.widgetBackground};
    border-radius: 10px;
  }

  .tank-info__header {
    display: flex;
    justify-content: space-between;
  }

  .tank-info__notifications-button {
    cursor: pointer;
  }

  .tank-info__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tank-info__inner {
    width: 100%;
    flex: 1;
    margin-top: 15px;
  }

  .tank-info__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 17px;
    }
  }

  .tank-info__item-key {
    flex: 1.3;
    color: ${({theme}) => opacify(0.1, theme.colors.secondary)};
  }

  .tank-info__item-value {
    flex: 1;
    color: ${({theme}) => theme.colors.mainText};
  }

  .tank-info__button {
    margin-top: auto;
  }

  .tank-notifications {
    width: 470px;
    background-color: red;
  }

  @media (max-width: 768px) {
    .tank-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .tank-card__wrapper,
    .tank-history {
      margin-right: 0;
    }

    .tank-card__wrapper {
      min-height: 539px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tank-history {
      width: auto;
      height: auto;
      min-width: 85.4vw;
      min-height: 75.2vw;
    }

    .tank-history__chart-container {
      width: auto;
      height: auto;
      min-height: 63vw;
    }

    .tank-info {
      min-width: 85.4vw;
      min-height: 75vw;
    }

    .tank-info__item-key {
      padding-right: 10px;
    }
  }

  @media (max-width: 420px) {
    .tank-card {
      width: auto;
    }

    .tank-card__wrapper {
      width: 90%;
      min-height: 124.8vw;
    }

    .tank-card__info {
      padding: 15px 15px 20px;
    }

    .tank-card__main-info {
      font-size: 4vw;
    }

    .tank-card__second-info-title {
      font-size: 6vw;
    }

    .tank-card__second-info-text {
      font-size: 3.5vw;
    }
  }
`;

export default StyledHome;
