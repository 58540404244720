import React, {useState} from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {FormikProps, FormikProvider} from 'formik';

import Button from 'components/Button/Button';
import Logo from 'assets/icons/Logo.svg';
import {SignInValues} from '../Sign.hook';
import {useAppSelector} from 'store/hooks';

type SignInProps = {
  setSubPage: Function;
  formik: FormikProps<SignInValues>;
};

const SignIn = ({setSubPage, formik}: SignInProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const {isLoading} = useAppSelector(({mainSlice: {signing}}) => ({
    isLoading: signing.isLoading,
  }));

  return (
    <div className="sign__inner sign__inner_sign-in">
      <div className="sign__header">
        <img
          className="sign__logo sign__logo_title"
          src={Logo}
          alt="logo"
          width={138}
        />

        <div className="header__title">Welcome back</div>
        <div className="header__subtitle">Please sign in to your account</div>
      </div>

      <FormikProvider value={formik}>
        <form className="sign__form">
          <div className="sign__input-item">
            <TextField
              className="sign__input"
              label="Email address"
              variant="standard"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.email && formik.touched.email)}
            />
          </div>

          <div className="sign__input-item">
            <TextField
              className="sign__input"
              label="Password"
              variant="standard"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.password && formik.touched.password)}
            />
          </div>

          <Button
            className="sign__submit-button"
            text="Sign In"
            onClick={formik.handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </form>
      </FormikProvider>

      <div className="sign__footer sign__footer_sign-in">
        <div className="sign__another-page">
          Don't have an account?{' '}
          <span className="sign__link" onClick={() => setSubPage('SignUp')}>
            Sign Up
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
