import React from 'react';

import BackArrow from 'assets/icons/BackArrow.svg';
import useSignHook from './Sign.hook';
import StyledSign from './Sign.style';

const Sign = () => {
  const {page, setSubPage, pageName} = useSignHook();

  return (
    <StyledSign>
      <div className="sign__left-part" />
      <div className="sign__right-part">
        {pageName !== 'SignStart' && (
          <img
            className="sign__back-arrow"
            src={BackArrow}
            alt="Back"
            onClick={() => setSubPage('SignStart')}
          />
        )}
        {page}
      </div>
    </StyledSign>
  );
};

export default Sign;
