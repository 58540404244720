import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Routes from 'routes/Routes';

import useAppHook from 'utils/hooks/App.hook';
import {useAppSelector} from 'store/hooks';

const App = () => {
  const {isMobileLayout, isSideBarOpen, setIsSideBarOpen} = useAppHook();

  const {authChecked} = useAppSelector(({mainSlice}) => ({
    authChecked: mainSlice.authChecked,
  }));

  return (
    <>
      {authChecked ? (
        <Routes
          isMobileLayout={isMobileLayout}
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
        />
      ) : null}
    </>
  );
};

export default App;
