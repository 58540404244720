import React, {ReactNode} from 'react';
import {ThemeProvider} from 'styled-components';
import {ThemeProvider as MaterialThemeProvider} from '@mui/material/styles';

import {useAppSelector} from 'store/hooks';
import {themes} from 'style/styled-components/themes';
import materialTheme from './material/materialTheme';
import GlobalStyle from './styled-components/globalStyles';
import {ThemeType} from 'utils/models';
interface ThemeProviderProps {
  children?: ReactNode;
}

const StyledThemeProvider: React.FC<ThemeProviderProps> = ({children}) => {
  const {theme} = useAppSelector(({mainSlice}) => ({
    theme: mainSlice.theme as ThemeType,
  }));

  return (
    <ThemeProvider theme={themes[theme]}>
      <MaterialThemeProvider theme={materialTheme}>
        <GlobalStyle />
        {children}
      </MaterialThemeProvider>
    </ThemeProvider>
  );
};

export default StyledThemeProvider;
