import React from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {ThreeDots} from 'react-loader-spinner';
import {useTheme} from 'styled-components';

import WeatherItem from './WeatherItem';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';

import {useAppSelector} from 'store/hooks';

const Weather = () => {
  const theme: any = useTheme();
  const {weather, tanks} = useAppSelector(({tankSlice: {weather, tanks}}) => ({
    weather: weather,
    tanks: tanks,
  }));

  if (weather.data[weather.selected].error || tanks[weather.selected].error) {
    return <div className="tank-loading">Weather loading error</div>;
  }

  if (!weather.data[weather.selected].loaded) {
    return (
      <div className="tank-loading tank-loading_weather">
        <ThreeDots
          height="100"
          width="100"
          color={theme.colors.primary}
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <ScrollMenu
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      onWheel={() => null}
      scrollContainerClassName="weather-container"
    >
      {weather.data[weather.selected].data.map((item: any) => (
        <WeatherItem key={item.date} item={item} itemId={item.date} />
      ))}
    </ScrollMenu>
  );
};

export default Weather;
