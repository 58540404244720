import React, {useState} from 'react';
import {Rings} from 'react-loader-spinner';
import Drawer from '@mui/material/Drawer';

import Notifications from './Notifications';
import BacteriologicalResults from './BacteriologicalResults';

import NotificationsIcon from 'assets/icons/Notifications.svg';
import {useTheme} from 'styled-components';
import {useAppSelector} from 'store/hooks';
import {SingleTankState} from 'utils/models';
import Button from 'components/Button/Button';

export interface TankInfoProps {
  tank: SingleTankState;
  itemId: string;
}

interface DrawerState {
  type?: 'Notifications' | 'Results';
  open: boolean;
}

const TankInfo = ({itemId, tank}: TankInfoProps) => {
  const [drawerProps, setDrawerProps] = useState<DrawerState>({
    type: 'Notifications',
    open: false,
  });
  const theme: any = useTheme();
  const {notifications} = useAppSelector(({tankSlice: {notifications}}) => ({
    notifications: notifications,
  }));

  const toggleDrawer =
    ({type, open}: DrawerState) =>
    () => {
      setDrawerProps({
        type: type,
        open: open,
      });
    };

  return (
    <div className="tank-info">
      <div className="tank-info__header">
        <div className="tank-item__title">Tank Info</div>
        <img
          className="tank-info__notifications-button"
          src={NotificationsIcon}
          alt="Notifications"
          onClick={toggleDrawer({type: 'Notifications', open: true})}
        />
      </div>

      <div className="tank-info__container">
        {tank.isLoading ? (
          <div className="tank-loading">
            <Rings
              height="100"
              width="100"
              color={theme.colors.primary}
              ariaLabel="loading"
            />
          </div>
        ) : tank.error ? (
          <div className="tank-loading">Tank data loading error</div>
        ) : (
          <>
            <div className="tank-info__inner">
              <div className="tank-info__inner-info">
                <div className="tank-info__item">
                  <div className="tank-info__item-key">Capacity</div>
                  <div className="tank-info__item-value">
                    {tank.data?.capacity || 0} gallons*
                  </div>
                </div>

                <div className="tank-info__item">
                  <div className="tank-info__item-key">Width</div>
                  <div className="tank-info__item-value">{tank.data.width}</div>
                </div>

                <div className="tank-info__item">
                  <div className="tank-info__item-key">Length</div>
                  <div className="tank-info__item-value">
                    {tank.data?.length || 0}
                  </div>
                </div>

                <div className="tank-info__item">
                  <div className="tank-info__item-key">Depth</div>
                  <div className="tank-info__item-value">{tank.data.depth}</div>
                </div>

                <div className="tank-info__item">
                  <div className="tank-info__item-key">№ people</div>
                  <div className="tank-info__item-value">
                    {tank.data.people}
                  </div>
                </div>

                <div className="tank-info__item">
                  <div className="tank-info__item-key">
                    Email notification level
                  </div>
                  <div className="tank-info__item-value">
                    {tank.data?.n_level || 0}%
                  </div>
                </div>
              </div>
            </div>

            <div className="tank-info__item">
              <div className="tank-info__item-key">* Gallons estimate</div>
            </div>

            <Button
              text="Bacteriological results"
              onClick={toggleDrawer({type: 'Results', open: true})}
            />

            {tank.data.id && (
              <Drawer
                anchor="right"
                open={drawerProps.open}
                onClose={toggleDrawer({open: false, type: drawerProps.type})}
              >
                {drawerProps.type === 'Notifications' ? (
                  <Notifications
                    notifications={notifications[tank.data.id]}
                    onDrawerClose={toggleDrawer({
                      open: false,
                      type: drawerProps.type,
                    })}
                  />
                ) : (
                  <BacteriologicalResults
                    results={tank.data.results}
                    onDrawerClose={toggleDrawer({
                      open: false,
                      type: drawerProps.type,
                    })}
                  />
                )}
              </Drawer>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TankInfo;
