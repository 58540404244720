import React from 'react';

import Sign from 'pages/Sign/Sign';
import Home from 'pages/Home/Home';
import Service from 'pages/Service/Service';
import Profile from 'pages/Profile/Profile';

const RoutesData = {
  loggedOut: [
    {
      name: 'Sign',
      path: '/sign',
      component: <Sign />,
    },
  ],

  loggedIn: [
    {
      name: 'Home',
      path: '/home',
      component: (isMobileLayout: boolean, isSideBarOpen: boolean) => (
        <Home isMobileLayout={isMobileLayout} isSideBarOpen={isSideBarOpen} />
      ),
    },
    {
      name: 'Service',
      path: '/service',
      component: (isMobileLayout: boolean, isSideBarOpen: boolean) => (
        <Service
          isMobileLayout={isMobileLayout}
          isSideBarOpen={isSideBarOpen}
        />
      ),
    },
    {
      name: 'Profile',
      path: '/profile',
      component: (isMobileLayout: boolean, isSideBarOpen: boolean) => (
        <Profile
          isMobileLayout={isMobileLayout}
          isSideBarOpen={isSideBarOpen}
        />
      ),
    },
  ],
};

export default RoutesData;
