import React, {useEffect} from 'react';

import Button from 'components/Button/Button';

import Cross from 'assets/icons/Cross.svg';
import RadioOff from 'assets/icons/RadioOff.svg';
import RadioOn from 'assets/icons/RadioOn.svg';
import StyledRequestService from './RequestService.style';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {requestService, resetRequestState} from 'store/slices/userSlice';

interface RequestServiceProps {
  selected: string | null;
  handleSelect: Function;
  onDrawerClose: () => void;
}

const RequestService = ({
  selected,
  handleSelect,
  onDrawerClose,
}: RequestServiceProps) => {
  const dispatch = useAppDispatch();
  const {extraServices, isReqLoading, isReqLoaded, isReqError, deviceIDs} =
    useAppSelector(({userSlice: {services, user}}) => ({
      extraServices: services.data.extraServices,
      isReqLoading: services.request.isLoading,
      isReqLoaded: services.request.loaded,
      isReqError: services.request.error,
      deviceIDs: user.data.deviceIDs,
    }));

  useEffect(() => {
    return () => {
      handleSelect(null);
      dispatch(resetRequestState());
    };
  }, [dispatch, handleSelect]);

  return (
    <StyledRequestService>
      <img
        className="request-service__close"
        src={Cross}
        alt="Close"
        onClick={onDrawerClose}
      />
      <div className="request-service__title">Request a Service</div>

      <div className="extra-services-inner">
        <div className="extra-services-list">
          {Object.keys(extraServices).map((service) => (
            <div
              className={`extra-service__item ${
                selected === service ? 'extra-service__item_selected' : ''
              }`}
              key={service}
              onClick={() => handleSelect(service)}
            >
              <img
                className="extra-service__radio"
                src={selected === service ? RadioOn : RadioOff}
                alt={`${selected === service ? '+' : '-'}`}
              />
              <div className="extra-service__title">{service}</div>
            </div>
          ))}
        </div>

        <div className="extra-services-footer">
          <div
            className={`extra-service__message ${
              isReqLoaded
                ? isReqError
                  ? 'extra-service__message_error'
                  : 'extra-service__message_success'
                : ''
            }`}
          >
            {isReqLoaded
              ? isReqError
                ? 'Service request error'
                : 'Service requested!'
              : ''}
          </div>

          <div className="extra-service__selected-description">
            {selected ? extraServices[selected] : ''}
          </div>

          <Button
            text="Request a Service"
            onClick={() => {
              if (selected) {
                dispatch(requestService({id: deviceIDs[0], service: selected}));
              }
            }}
            disabled={isReqLoading || !selected}
            isLoading={isReqLoading}
          />
        </div>
      </div>
    </StyledRequestService>
  );
};

export default RequestService;
