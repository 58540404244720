import React from 'react';
import MaterialModal from '@mui/material/Modal';

import StyledModal from './Modal.style';

interface ModalProps {
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({children, open, onClose}: ModalProps) => {
  return (
    <MaterialModal open={open} onClose={onClose}>
      <StyledModal>{children}</StyledModal>
    </MaterialModal>
  );
};

export default Modal;
