import React from 'react';

import Rain from 'assets/icons/Rain.svg';
import Wind from 'assets/icons/Wind.svg';

const WeatherItem = ({item, itemId}: any) => {
  return (
    <div className="weather__item">
      <div className="weather__item-title">{item.date}</div>

      <div className="weather__item-inner">
        <div className="weather__item-info">
          <div className="weather__item-info-title">Rain</div>
          <div className="weather__item-info-inner">
            <div className="weather__item-info-icon">
              <img src={Rain} alt="Rain" />
            </div>
            <div className="weather__item-info-value">{item.chanceOfRain}%</div>
          </div>
        </div>

        <div className="weather__item-info">
          <div className="weather__item-info-title">Wind</div>
          <div className="weather__item-info-inner">
            <div className="weather__item-info-icon">
              <img src={Wind} alt="Wind" />
            </div>
            <div className="weather__item-info-value">
              {item.windSpeed} km/h
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherItem;
