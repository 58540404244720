import React from 'react';
import {Rings} from 'react-loader-spinner';
import {useTheme} from 'styled-components';

import StyledButton from './Button.style';

interface ButtonBaseProps {
  text: string;
  onClick: Function;
  className?: string;
  outlined?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const Button = ({
  text,
  onClick,
  className,
  outlined,
  isLoading,
  disabled,
}: ButtonBaseProps) => {
  const theme: any = useTheme();

  return (
    <StyledButton
      className={className}
      type="button"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      outlined={outlined}
    >
      {isLoading ? (
        <div className="button__loading">
          <Rings
            color={outlined ? theme.colors.primary : theme.colors.background}
            height="50"
            width="50"
            ariaLabel="loading"
          />
        </div>
      ) : (
        text
      )}
    </StyledButton>
  );
};

export default Button;
